import { createSlice } from "@reduxjs/toolkit";

export const TAB = {
  REALTIME_DATA: "realtimedata",
  CHART: "chart"
}

const savedTab = localStorage.getItem("selectedTab") || TAB.REALTIME_DATA;

export const initialState = {
  tab: savedTab
};

const LayoutReducer = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setTab(state, action) {
      console.log("setTab");
      console.log(action);
      localStorage.setItem("selectedTab", action.payload); 
      return {
        ...state,
        tab: action.payload
      }
    },
    resetState: (state) => {return {...initialState}},
  },
});

export const {
  resetState,
  setTab
} = LayoutReducer.actions

export default LayoutReducer.reducer;