import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const DownArrowButton = ({ enabled, size, onClick, children, style }) => {
  return (
    <div
    style={{
      cursor: enabled ? 'pointer' : 'not-allowed',
      padding: '10px 20px',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',      
      marginTop: '-20px',
      marginBottom: '-10px'
    }}
    onClick={enabled ? onClick : null}
    disabled={!enabled}
    >
      <FontAwesomeIcon icon={faCaretDown} size={size} style={{ color: enabled ? 'green' : 'gray', ...style }}/>
      {children}
    </div>
  );
};

export default DownArrowButton;
