module.exports = {
    DEVICE_TYPE: {
        LUMINANCE: "luminance",
        PH: "ph",
        EC: "ec",
        WATERFLOW: "waterflow",
        WATER_LEVEL: "water_level",
        WATER_TEMP: "water_temperature",
        INDOOR_TEMPERATURE: "indoor_temperature",
        INDOOR_HUMIDITY: "indoor_humidity",
        INDOOR_CO2: "indoor_CO2",
        PH_PLUS_PUMP: "ph_plus_pump",
        PH_MINUS_PUMP: "ph_minus_pump",
        EC_A_PUMP: "ec_a_pump",
        EC_B_PUMP: "ec_b_pump",
        WATER_PLUS_PUMP: "water_plus_pump",
        DISSOLVED_O2: "dissolved_o2",
        LIGHT_CONTROL: "light_control"
    },
    DEVICE_UNIT: {
        degreeC: "degreeC",
        percentage: "percentage"
    },
    COMPONENT_TYPE: {
        EC: "EC",
        PH: "PH",
        EC_PH: "EC_PH",
        LUMINANCE: "LUMINANCE",
        LUMINANCE_LIGHT: "LUMINANCE_LIGHT",
        EC_PH_MIX_PUMP: "EC_PH_MIX_PUMP",
        DISSOLVED_O2: "DISSOLVED_O2",
        EC_PH_WL: "EC_PH_WL",
        EC_PH_WL_MIX_PUMP_WATER_PLUS_PUMP: "EC_PH_WL_MIX_PUMP_WATER_PLUS_PUMP",
        INDOOR_CO2: "INDOOR_CO2",
        INDOOR_HUMIDITY: "INDOOR_HUMIDITY",
        INDOOR_LUMINANCE: "INDOOR_LUMINANCE",
        INDOOR_TEMP: "INDOOR_TEMP",
        WATERFLOW: "WATERFLOW",
        WATER_LEVEL: "WATER_LEVEL",
        WATER_TEMP: "WATER_TEMP"
    },
    DEVICE_STATUS: {
        ON: "ON",
        OFF: "OFF",
        NO_STATUS: "NO_STATUS"
    },
    DEVICE_VALUE_TYPE: {
        MONITOR: "MONITOR",
        CONTROL: "CONTROL"
    },
    CONTROL_SETTING_NAME: {
        LUMINANCE_LIGHT: {
            TURN_ON_TIME: "turnOnTime",
            TURN_OFF_TIME: "turnOffTime"
        }
    }
}