import { socket } from "../config";
import { io } from "socket.io-client";


export default class DataConnector {
    static instance = null;

    static getInstance() {
        if (this.instance === null) {
            this.instance = new DataConnector();
        }
        return this.instance;
    }

    socket = null;

    onConnected = null;
    onDisconnected = null;
    onDeviceStatusChange = null;
    onComponentAutomaticChange = null;
    onComponentArgumentChange = null;
    log = (tb, ...args) => {
        console.log(`[${this.NAME}-${tb}]`, ...args)
    }

    constructor() {
        this.log('constructor', `Socket connecting to ${socket.SOCKET_URL} ${socket.SOCKET_PATH}`);
    }

    createSocket() {        
        if (this.socket !== null && this.socket.connected) {
            this.closeSocket();
        }
        let token = JSON.parse(sessionStorage.getItem("authUser")).credential.accessToken;
        try {
            this.socket = io(socket.SOCKET_URL, {
                query: 'token=' + token
            });
        }
        catch (error) {
            console.log("socket error")
            console.log(error);
        }
    }

    closeSocket() {
        if (this.socket !== null) {
            this.socket.disconnect();
        }
    }

    setCBs(onConnected, onDisconnected, onDeviceStatusChange, onComponentAutomaticChange, onComponentArgumentChange, onComponentStatusChange) {
        this.onConnected = onConnected;
        this.onDisconnected = onDisconnected;
        this.onDeviceStatusChange = onDeviceStatusChange;
        this.onComponentAutomaticChange = onComponentAutomaticChange;
        this.onComponentArgumentChange = onComponentArgumentChange;
    }

    setup() {
        this.socket.on("connect", () => {
            console.info("Socket Connected", this.socket.id);
            this.onConnected();
        })
        this.socket.on("disconnect", (reason, description) => {
            console.info("Socket Disconnected", reason, description);
            this.onDisconnected();
        })
        this.socket.on("connect_error", (error) => {
            console.info("Socket Connect Error", error.name, error.message);
        })
        this.socket.on('DEVICE_STATUS_CHANGE', (data) => {
            console.info("Socket: DEVICE_STATUS_CHANGE", data);
            this.onDeviceStatusChange?.(data);
        })
        this.socket.on('COMPONENT_AUTOMATIC_UPDATE', (data) => {
            console.info("Socket: COMPONENT_AUTOMATIC_UPDATE", data);
            this.onComponentAutomaticChange?.(data);
        })
        this.socket.on('COMPONENT_ARGUMENT_UPDATE', (data) => {
            console.info("Socket: COMPONENT_ARGUMENT_UPDATE", data);
            this.onComponentArgumentChange?.(data);
        })
    }
}