import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../slices/thunks";
import { getOrganizationData, getOrganizationDevice } from "../../slices/data/thunk";
import DataConnector from "../../common/DataConnector";
import { updateComponentArgumentSuccess, updateComponentAutomaticSuccess, updateOrganizationDataSuccess } from "../../slices/data/reducer";
import ChartSection from "./ChartSection";
import EC_PH from "./components/EC_PH";
import { COMPONENT_TYPE } from "../../common/constant";
import LuminanceLight from "./components/LuminanceLight";
import EC_PH_MIX_PUMP from "./components/EC_PH_MIX_PUMP";
import MonitoringDevice from "./components/MonitoringDevice";
import { getAPIVersion } from "../../services/developer";
import { formatTimestamp } from "../../common/util";
import { setTab, TAB } from "../../slices/layout/reducer";

const SPARKLINE_COLOR = ['#72c2ff', '#9ce89d', '#fda582'];

const getSparlineColor = (count) => {
  let countNum = count % 3;
  return SPARKLINE_COLOR[countNum];
}

export const getZoneHeader = (count, name, t) => {
  let countNum = count + 1;
  let colorTone = countNum%2===0?'l-green':countNum%3===0?'l-amber':'l-blue';
  return (
    <div key={count} className={"card dash-zone-header " + colorTone}>
      <div className="dash-zone-header-text">
        <h3>{t('Common.Zone') + t('Common.Space') + name}</h3>
      </div>
  </div>
  );
}

const onConnected = (dispatch) => {
  console.log("onConnected dispatch");

};
const onDisconnected = (dispatch) => {
  console.log("onDisconnected dispatch");
};
const onDeviceStatusChange = (dispatch, message) => {
  console.log("onDeviceStatusChange dispatch");
  console.log(message);
  dispatch(updateOrganizationDataSuccess({data: JSON.parse(message)}))
};
const onComponentAutomaticChange = (dispatch, data) => {
  console.log("onComponentAutomaticChange dispatch");
  console.log(data);
  dispatch(updateComponentAutomaticSuccess({data: data}))
};
const onComponentArgumentChange = (dispatch, data) => {
  console.log("onComponentArgumentChange dispatch");
  console.log(data);
  dispatch(updateComponentArgumentSuccess({data: data}))
};

function Dashboard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.Login.user);
  const memoizedUser = useMemo(() => user, [user]);
  const logoPath = memoizedUser.user?.organizationLogo ?? null;
  const organizationName = memoizedUser.user?.organizationName ?? "";
  const dataSeq = useSelector(state => state.Data.seq);
  const dataZone = useSelector(state => state.Data.zone);
  const activityID = useSelector(state => state.Data.activityID);
  const dataComponent = useSelector(state => state.Data.component);
  const dataLastUpdatedTime = useSelector(state => state.Data.dataLastUpdatedTime);
  const tab = useSelector(state => state.Layout.tab);
  const [imageSrc, setImageSrc] = useState('');
  const [version, setVersion] = useState('');

  useEffect(() => {
    const importImage = async () => {
      try {
        const imageModule = await import(`../../assets/images/companyLogo/${logoPath}`);
        setImageSrc(imageModule.default);
      } catch (error) {
        console.error('Error importing image:', error);
      }
    };
    if (logoPath !== null && logoPath !== undefined) {
      importImage();
    }
  }, [logoPath]);

  useEffect(() => {
    dispatch(getOrganizationDevice());
    dispatch(getOrganizationData());
    
    // start socket
    const dataConnector = DataConnector.getInstance();
    dataConnector.createSocket();
    dataConnector.setCBs(
      () => {onConnected(dispatch)}, 
      () => {onDisconnected(dispatch)}, 
      (data) => {onDeviceStatusChange(dispatch, data)},
      (data) => {onComponentAutomaticChange(dispatch, data)},
      (data) => {onComponentArgumentChange(dispatch, data)});
    dataConnector.setup();
  }, []);

  useEffect(() => {
    const fetchVersion = async () => {
        try {
            const result = await getAPIVersion();
            setVersion(result.version); // Adjust based on your API response structure
        } catch (error) {
            console.error('Error fetching API version:', error);
        }
    };

    fetchVersion(); // Call the async function
  }, []);


  return (
    <React.Fragment>
      <div id="wrapper">
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-brand dash-navbar-brand">
              {logoPath !== undefined && logoPath !== "" ?
              <img src={imageSrc} className="dash-logo" style={{paddingRight: 5}}/>:null}
              <h4 className="dash-header">{organizationName}</h4>
            </div>
            <div className="navbar-right dash-navbar-right">
              <div id="navbar-menu">
                <ul className="nav navbar-nav">
                  {dataLastUpdatedTime !== ""?
                  <li>
                    <div style={{marginRight: 10}}>{t('Common.DATA_LAST_UPDATE_TIME') + ": " + formatTimestamp(dataLastUpdatedTime)}</div>
                  </li>:null}
                  {tab === TAB.REALTIME_DATA?
                  <li>
                    <a className="icon-menu"><i className="icon-pie-chart" onClick={() => {
                      dispatch(setTab(TAB.CHART));
                    }}></i></a>
                  </li>:
                  <li>
                  <a className="icon-menu"><i className="icon-home" onClick={() => {
                    dispatch(setTab(TAB.REALTIME_DATA));
                  }}></i></a>
                  </li>}
                  <li>
                    <a className="icon-menu"><i className="icon-login" onClick={() => {
                      dispatch(logoutUser());
                    }}></i></a>
                  </li>
                  <li>
                    <div style={{marginLeft: 10, marginRight: 20}}>{version}</div>                  
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div id="main-content">
          <div className="container-fluid">
            {tab === TAB.REALTIME_DATA?
            <div className="row g-3">
              {
                dataSeq.map((zoneID, index) => {
                  let zone = dataZone[zoneID];
                  if (zone === undefined || zone === null) {
                    return null;
                  }
                  else {
                    return (
                    <div key={zoneID} className="col-lg-4 col-md-12">
                      {getZoneHeader(index, zone.zoneName, t)}
                      {
                        zone.components.map(componentId => {
                          console.log("componentId: " + componentId);
                          let component = dataComponent[componentId];
                          console.log(component.componentType);
                          if (component !== undefined) {
                            if (component.componentType === COMPONENT_TYPE.EC || component.componentType === COMPONENT_TYPE.PH || component.componentType === COMPONENT_TYPE.LUMINANCE || component.componentType === COMPONENT_TYPE.WATERFLOW
                               || component.componentType === COMPONENT_TYPE.WATER_LEVEL || component.componentType === COMPONENT_TYPE.WATER_TEMP || component.componentType === COMPONENT_TYPE.INDOOR_CO2
                               || component.componentType === COMPONENT_TYPE.INDOOR_HUMIDITY || component.componentType === COMPONENT_TYPE.INDOOR_LUMINANCE || component.componentType === COMPONENT_TYPE.INDOOR_TEMP || component.componentType === COMPONENT_TYPE.DISSOLVED_O2) {
                              return <MonitoringDevice key={component.componentId} activityID={activityID} componentId={component.componentId} sparklineColor={getSparlineColor(index)}/>;
                            }
                            if (component.componentType === COMPONENT_TYPE.EC_PH || component.componentType === COMPONENT_TYPE.EC_PH_WL) {
                              return <EC_PH key={component.componentId} activityID={activityID} componentId={component.componentId} sparklineColor={getSparlineColor(index)}/>;
                            }
                            else if (component.componentType === COMPONENT_TYPE.LUMINANCE_LIGHT) {
                              console.log("return LuminanceLight");
                              return <LuminanceLight key={componentId} activityID={activityID} componentId={componentId} sparklineColor={getSparlineColor(index)}/>
                            }
                            else if (component.componentType === COMPONENT_TYPE.EC_PH_MIX_PUMP) {
                              console.log("return EC_PH_MIX_PUMP");
                              return <EC_PH_MIX_PUMP key={componentId} activityID={activityID} componentId={componentId} sparklineColor={getSparlineColor(index)} isWithPlusWL={false}/>
                            }
                            else if (component.componentType === COMPONENT_TYPE.EC_PH_WL_MIX_PUMP_WATER_PLUS_PUMP) {
                              console.log("return EC_PH_WL_MIX_PUMP_WATER_PLUS_PUMP");
                              return <EC_PH_MIX_PUMP key={componentId} activityID={activityID} componentId={componentId} sparklineColor={getSparlineColor(index)} isWithPlusWL={true}/>
                            }
                          }                          
                        })
                      }
                    </div>
                    );
                  }
                })
              }              
            </div>
            :<ChartSection />}             
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
