import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { DEVICE_TYPE } from "../../../common/constant";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { getIcon, getUnit } from "../../../common/util";

function MonitoringDevice(props) {
  const { t } = useTranslation();
  const id = props.componentId;
  const component = useSelector(state => state.Data.component[id]);
  const deviceIds = component.devices;
  const devicesState = useSelector(state => state.Data.data);
  const data = useMemo(() => {
    return deviceIds.reduce((acc, id) => {
      // Fetching the device data from the Redux store
      acc[id] = devicesState?.[id];
      return acc;
    }, {});
  }, [deviceIds, devicesState]); // Dependencies are deviceIds and devicesState
  const devicesInfo = useSelector(state => state.Data.device);

  // Memoize the data object with useMemo
  const devices = useMemo(() => {
    return deviceIds.reduce((acc, id) => {
      // Fetching the device data from the Redux store
      acc[id] = devicesInfo?.[id];
      return acc;
    }, {});
  }, [deviceIds, devicesInfo]); // Dependencies are deviceIds and devicesState

  const divName = "C_" + component.componentId;
  console.log(data);
  return (
    <div className="card dash-zone-item-card dash-zone-item-card-ec-ph">
      <div className="body">          
          {
            component.devices.map(deviceID => {
              let device = devices[deviceID];
              return (
                <div key={device.deviceId} className="row dash-zone-item">
                  <div className="col-12 dash-zone-item-header-container">
                    <div className="dash-zone-item-header">{t('Common.DeviceType.' + device.deviceTypeName)}</div>
                  </div>
                  <div className="col-12 dash-zone-item-container">
                    <div className="col-4 dash-zone-item-icon"><FontAwesomeIcon icon={getIcon(device.deviceTypeName)} /></div>
                    <div className="col-8 dash-zone-item-text-container">
                      {data[device.deviceId] === undefined || data[device.deviceId].value === undefined ? "-" :
                        <Fragment>
                          <div className="dash-zone-item-text">{data[device.deviceId].value}</div>
                          <div className="dash-zone-item-unit">{getUnit(device.deviceTypeValueUnit)}</div>
                          {device.deviceType !== DEVICE_TYPE.WATER_LEVEL?
                          <div id={divName} style={{ width: "100%", height: "40px", marginLeft: '2rem', maxWidth: "200px" }}></div>
                          :null}
                        </Fragment>
                      }
                    </div>
                  </div>    
                </div>
              );
            })
          }      
      </div>
    </div>
  );
}

export default MonitoringDevice;