import React, { Fragment, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_TYPE } from "../../../common/constant";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { getIcon, getUnit } from "../../../common/util";
import SliderComponent from "../../../components/SliderComponent";
import { updateComponentInDB } from "../../../slices/data/thunk";
import WarningSign from "../../../components/WarningSign";

function EC_PH(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const id = props.componentId;
  const component = useSelector(state => state.Data.component[id]);
  const argument = useSelector(state => state.Data.component[id]?.argument);
  const deviceIds = component.devices;
  const devicesState = useSelector(state => state.Data.data);
  const [ecID, setEcID] = useState(null);
  const [phID, setPhID] = useState(null);
  const devicesInfo = useSelector(state => state.Data.device);

  const data = useMemo(() => {
    return deviceIds.reduce((acc, id) => {
      // Fetching the device data from the Redux store
      acc[id] = devicesState?.[id];
      if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.EC) {
        setEcID(id);
      }
      else if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.PH) {
        setPhID(id);
      }
      return acc;
    }, {});
  }, [deviceIds, devicesState]); // Dependencies are deviceIds and devicesState

  // Memoize the data object with useMemo
  const devices = useMemo(() => {
    return deviceIds.reduce((acc, id) => {
      // Fetching the device data from the Redux store
      acc[id] = devicesInfo?.[id];
      return acc;
    }, {});
  }, [deviceIds, devicesInfo]); // Dependencies are deviceIds and devicesState

  const handleUpdateArgument = async (newArgument) => {
    try {      
      if (newArgument !== undefined) {
        console.log(JSON.stringify(newArgument)); //{"ecMax":"10","ecMin":"2", "phMin": "6", "phMax": "9"}
        await dispatch(updateComponentInDB({aid: props.activityID, cid: id, argument: newArgument}));
        console.log("handleAction Device Database updated successfully");
      }
    } catch (error) {
      console.error("Error updating database:", error);
    }
  }

  const divName = "C_" + component.componentId;
  console.log(data);
  return (
    <div className="card dash-zone-item-card dash-zone-item-card-ec-ph">
      <div className="body">          
          {
            component.devices.map(deviceID => {
              let device = devices[deviceID];
              return (
                <div key={device.deviceId} className="row dash-zone-item">
                  <div className="col-12 dash-zone-item-header-container">
                    <div className="dash-zone-item-header">{t('Common.DeviceType.' + device.deviceTypeName)}</div>
                  </div>
                  <div className="col-12 dash-zone-item-container">
                    <div className="col-4 dash-zone-item-icon"><FontAwesomeIcon icon={getIcon(device.deviceTypeName)} /></div>
                    <div className="col-8 dash-zone-item-text-container">
                      {data[device.deviceId] === undefined || data[device.deviceId].value === undefined ? "-" :
                        <Fragment>
                          <div className="dash-zone-item-text">{data[device.deviceId].value}</div>
                          <div className="dash-zone-item-unit">{getUnit(device.deviceTypeValueUnit)}</div>
                          {(data[device.deviceId].alert === undefined || !data[device.deviceId].alert)?
                          <div id={divName} style={{ width: "100%", height: "40px", marginLeft: '2rem', maxWidth: "200px" }}></div>
                          :<WarningSign title={t("ALERT_MSG." + data[device.deviceId].alertMsg)}/>}   
                        </Fragment>
                      }
                    </div>
                  </div>    
                </div>
              );
            })
          }      
      </div>
      <div className="dash-zone-item-card-control">
      <SliderComponent className="slider" title={t('Common.SET_EC_RANGE')} deviceID={ecID} selectedLow={argument?.ecMin} selectedHigh={argument?.ecMax} setSelected={(valueLow, valueHigh)=>{console.log("calleed setSelected"); handleUpdateArgument({"ecMax": valueHigh, "ecMin": valueLow})}}/>
        <SliderComponent className="slider" title={t('Common.SET_PH_RANGE')} deviceID={phID} selectedLow={argument?.phMin} selectedHigh={argument?.phMax} setSelected={(valueLow, valueHigh)=>{handleUpdateArgument({"phMax": valueHigh, "phMin": valueLow})}}/>
      </div>
    </div>
  );
}

export default EC_PH;