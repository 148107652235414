//LOGIN
export const POST_LOGIN = "/auth/login";

//DATA
export const GET_ORGANIZATION_DEVICE = "/data/organization/device"
export const GET_ORGANIZATION_DATA = "/data/organization/data"
export const GET_ORGANIZATION_DATA_CHART = "/data/organization/chart"

export const UPDATE_COMPONENT = "/data/component"
export const GET_VERSION = "/api/version";
