import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEVICE_STATUS } from '../common/constant';
import UpArrowButton from './UpArrowButton';
import { useTranslation } from 'react-i18next';
import { faArrowUpFromWaterPump } from '@fortawesome/free-solid-svg-icons';
import Spinner from './Spinner';
import { useSelector } from 'react-redux';

const PhPlusControl = ({ deviceID, handleAction, isAutomatic }) => {
    const { t } = useTranslation();
    const status = useSelector(state => state.Data.data[deviceID]?.value);
    const [loading, setLoading] = useState(false);
    const prevStatusRef = useRef(status);
    console.log("Loading: " + loading);

    useEffect(() => {
        if (prevStatusRef.current !== status) {
            console.log(`Status changed from ${prevStatusRef.current} to ${status}`);
            if (status === DEVICE_STATUS.ON) {
                setLoading(false);
            }
            prevStatusRef.current = status;
        }
    }, [status]); 

    return (
        <div className="pump-control-container-title">
            <div className="title">{t('Common.DeviceType.ph_plus_pump')}</div>
            {status === undefined || status === DEVICE_STATUS.NO_STATUS? <div>{t('Common.NO_STATUS')}</div>:
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center"}}>
            <FontAwesomeIcon size={"2x"} icon={faArrowUpFromWaterPump} style={{ color: status === DEVICE_STATUS.ON ? 'green' : 'gray' }}/>
            {isAutomatic?null:
            <UpArrowButton size={"4x"} enabled={status === DEVICE_STATUS.ON ? false: true} onClick={()=>{
                if (status === DEVICE_STATUS.OFF) {
                    handleAction(deviceID, DEVICE_STATUS.ON);
                    setLoading(true);
                }
            }} />}
            {loading&&!isAutomatic?<Spinner/>:null}
            </div>}
        </div>
    );
};

export default PhPlusControl;
