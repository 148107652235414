import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  activityID: null,
  zone: {},
  seq: [],
  data: {},
  component: {},
  device: {},
  chart: {},
  dataLastUpdatedTime: ""
};

const DataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    getOrganizationDeviceSuccess(state, action) {
      return {
        ...state,
        ...action.payload.data,
      }
    },
    getOrganizationDataSuccess(state, action) {
      console.log(action);
      const firstKey = Object.keys(action.payload.data)[0];
      let dataLastUpdatedTime = "";
      if (firstKey) {
        dataLastUpdatedTime = action.payload.data[firstKey].date; 
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        dataLastUpdatedTime: dataLastUpdatedTime
      }
    },
    getOrganizationDataChartSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        chart: {
          ...action.payload.data,
        }
      }
    },
    updateOrganizationDataSuccess(state, action) {
      console.log(action);
      const firstKey = Object.keys(action.payload.data)[0];
      let dataLastUpdatedTime = "";
      if (firstKey) {
        dataLastUpdatedTime = action.payload.data[firstKey].date; 
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data
        },
        dataLastUpdatedTime: dataLastUpdatedTime
      }
    },
    updateComponentAutomaticSuccess(state, action) {
      console.log(action);
      const { componentID, value } = action.payload.data;
      const component = state.component[componentID];
      if (!component) return; // If zone not found, do nothing

      const updatedComponent = {
        ...component,
        isAutomatic: value
      };

      return {
        ...state,
        component: {
          ...state.component,
          [componentID]: updatedComponent
        }
      }
    },
    updateComponentArgumentSuccess(state, action) {
      console.log(action);
      const { componentID, value } = action.payload.data;
      const component = state.component[componentID];
      if (!component) return; // If zone not found, do nothing

      console.log(component);
      console.log("-----");
      const updatedComponent = {
        ...component,
        argument: value
      };
      console.log(updatedComponent);

      return {
        ...state,
        component: {
          ...state.component,
          [componentID]: updatedComponent
        }
      }
    },
    resetState: (state) => {return {...initialState}},
  },
});

export const {
  getOrganizationDeviceSuccess,
  getOrganizationDataSuccess,
  getOrganizationDataChartSuccess,
  updateOrganizationDataSuccess,
  updateComponentAutomaticSuccess,
  updateComponentArgumentSuccess,
  resetState,
} = DataSlice.actions

export default DataSlice.reducer;