import React, { Fragment, useMemo, useState } from "react";
import {  useSelector } from "react-redux";
import { DEVICE_VALUE_TYPE } from "../../../common/constant";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { getIcon, getUnit } from "../../../common/util";
import { LightControl } from "../devices/LightControl";



function LuminanceLight(props) {
  const { t } = useTranslation();
  const id = props.componentId;
  const component = useSelector(state => state.Data.component[id]);
  const deviceIds = component.devices;
  const devicesStatus = useSelector(state => state.Data.data);
  const devicesInfo = useSelector(state => state.Data.device);
  const [lightControlID, setLightControlID] = useState(null);

  // Memoize the data object with useMemo
  const devices = useMemo(() => {
    return deviceIds.reduce((acc, id) => {
      // Fetching the device data from the Redux store
      acc[id] = devicesInfo?.[id];
      if (devicesInfo?.[id].deviceValueType === DEVICE_VALUE_TYPE.CONTROL) {
        setLightControlID(id);
      }
      return acc;
    }, {});
  }, [deviceIds, devicesInfo]); // Dependencies are deviceIds and devicesState

    // Memoize the data object with useMemo
  const data = useMemo(() => {
    return deviceIds.reduce((acc, id) => {
      // Fetching the device data from the Redux store
      acc[id] = devicesStatus?.[id];
      return acc;
    }, {});
  }, [deviceIds, devicesStatus]); // Dependencies are deviceIds and devicesState

  const divName = "C_" + component.componentId;
  console.log(data);
  return (
    <div key={divName} className="card dash-zone-item-card dash-zone-item-card-luminance-light">
      <div className="body">          
          {
            component.devices.map(deviceID => {
              let device = devices[deviceID];
              if (device.deviceValueType === DEVICE_VALUE_TYPE.MONITOR) {
                return (
                  <div key={device.deviceId} className="row dash-zone-item">
                    <div className="col-12 dash-zone-item-header-container">
                      <div className="dash-zone-item-header">{t('Common.DeviceType.' + device.deviceTypeName)}</div>
                    </div>
                    <div className="col-12 dash-zone-item-container">
                      <div className="col-4 dash-zone-item-icon"><FontAwesomeIcon icon={getIcon(device.deviceTypeName)} /></div>
                      <div className="col-8 dash-zone-item-text-container">
                      {data[device.deviceId] === undefined || data[device.deviceId].value === undefined ? "-" :
                        <Fragment>
                        <div className="dash-zone-item-text">{data[device.deviceId].value}</div>
                        <div className="dash-zone-item-unit">{getUnit(device.deviceTypeValueUnit)}</div>
                        </Fragment>}
                      </div>
                    </div>    
                  </div>
                );
              }         
            })
          }      
      </div>
      <LightControl activityID={props.activityID} componentID={id} deviceID={lightControlID}/>
    </div>
  );
}

export default LuminanceLight;