import { combineReducers } from "redux";

import LoginReducer from "./auth/reducer";
import DataReducer from "./data/reducer";
import LayoutReducer from "./layout/reducer";


const rootReducer = combineReducers({
    Login: LoginReducer,
    Data: DataReducer,
    Layout: LayoutReducer
});

export default rootReducer;