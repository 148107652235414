import { faWater, faFlask, faBolt, faTemperatureHalf, faGlassWhiskey, faDroplet, faCloud, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { DEVICE_TYPE, DEVICE_UNIT } from './constant';

export const getIcon = (deviceType) => {
    if (deviceType === DEVICE_TYPE.LUMINANCE) {
      return faLightbulb;
    }
    else if (deviceType === DEVICE_TYPE.WATERFLOW) {
      return faWater;
    }
    else if (deviceType === DEVICE_TYPE.WATER_LEVEL) {
      return faGlassWhiskey;
    }
    else if (deviceType === DEVICE_TYPE.PH) {
      return faFlask;
    }
    else if (deviceType === DEVICE_TYPE.EC) {
      return faBolt;
    }
    else if (deviceType === DEVICE_TYPE.WATER_TEMP || deviceType === DEVICE_TYPE.INDOOR_TEMPERATURE) {
      return faTemperatureHalf;
    }
    else if (deviceType === DEVICE_TYPE.INDOOR_HUMIDITY) {
      return faDroplet;
    }
    else if (deviceType === DEVICE_TYPE.INDOOR_CO2) {
      return faCloud;
    }
    else if (deviceType === DEVICE_TYPE.DISSOLVED_O2) {
      return faDroplet;
    }
  }
  
export const getUnit = (unit) => {
  if (unit === DEVICE_UNIT.degreeC) {
    return "°C";
  }
  else if (unit === DEVICE_UNIT.percentage) {
    return "%";
  }
  return unit;
}
  
export const formatTimestamp = (timestamp) => {
    // Convert the timestamp to a JavaScript Date object
    const date = new Date(parseFloat(timestamp));  // Ensure the timestamp is treated as a number

    // Get the components of the date in the local time zone
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');  // Month is 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the date as yyyyMMdd hh:mm:ss
    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
}