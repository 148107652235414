import { APIClient } from "../common/apiHelper";
import { GET_VERSION } from "../common/urlHelper";

const api = new APIClient();

export const getAPIVersion = async () => {
    let result = await api.get(GET_VERSION);
    console.log("getAPIVersion");
    console.log(result);
    return result;
};
