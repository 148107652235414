import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_STATUS } from "../../../common/constant";

import { useTranslation } from "react-i18next";
import { updateComponentInDB } from "../../../slices/data/thunk";
import 'nouislider/dist/nouislider.css'; // Import CSS
import SliderComponent from "../../../components/SliderComponent";
import PhPlusControl from "../../../components/PhPlusControl";
import PhMinusControl from "../../../components/PhMinusControl";
import EcMinusControl from "../../../components/EcMinusControl";
import WaterLevelPlusControl from "../../../components/WaterLevelPlusControl";

export function EcPhPumpControl(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const componentID = props.componentID;
    const phPlusPumpDeviceID = props.phPlusPumpDeviceID;
    const phMinusPumpDeviceID = props.phMinusPumpDeviceID;
    const ecAPumpDeviceID = props.ecAPumpDeviceID;
    const ecBPumpDeviceID = props.ecBPumpDeviceID;
    const waterPlusPumpDeviceID = props.isWithPlusWL?props.waterPlusPumpDeviceID:undefined;
    const ecID = props.ecDeviceID;
    const phID = props.phDeviceID;
    
    const isAutomatic = useSelector(state => state.Data.component[componentID]?.isAutomatic);
    const argument = useSelector(state => state.Data.component[componentID]?.argument);
    const phPlusPumpDeviceStatus = useSelector(state => state.Data.data[phPlusPumpDeviceID]?.value);
    const phMinusPumpDeviceStatus = useSelector(state => state.Data.data[phMinusPumpDeviceID]?.value);
    const ecAPumpDeviceStatus = useSelector(state => state.Data.data[ecAPumpDeviceID]?.value);
    const ecBPumpDeviceStatus = useSelector(state => state.Data.data[ecBPumpDeviceID]?.value);
    const waterPlusPumpDeviceStatus = useSelector(state => state.Data.data[waterPlusPumpDeviceID]?.value);

    const handleToggleAutomatic = async () => {
        // Toggle the state
      const newAutomaticState = !isAutomatic;
  
      // Update the DB with the new state
      await updateAutomaticInDB(newAutomaticState);
    };
  
    const updateAutomaticInDB = async (newState) => {
      try {
        // Assuming updateComponentInDB is a function passed as a prop or defined elsewhere
        // This should be an API call to update the component in the database
        await dispatch(updateComponentInDB({aid: props.activityID, cid: componentID, isAutomatic: newState }));
        console.log("updateAutomaticInDB Database updated successfully");
      } catch (error) {
        console.error("Error updating database:", error);
      }
    };

    const isComponentReady = () => {
        return phPlusPumpDeviceStatus !== undefined && phPlusPumpDeviceStatus !== DEVICE_STATUS.NO_STATUS && phMinusPumpDeviceStatus !== undefined && phMinusPumpDeviceStatus !== DEVICE_STATUS.NO_STATUS && ecAPumpDeviceStatus !== undefined && ecAPumpDeviceStatus !== DEVICE_STATUS.NO_STATUS && ecBPumpDeviceStatus !== undefined && ecBPumpDeviceStatus !== DEVICE_STATUS.NO_STATUS && (!props.isWithPlusWL || (waterPlusPumpDeviceStatus !== undefined && waterPlusPumpDeviceStatus !== DEVICE_STATUS.NO_STATUS));
    }
    
    const handleAction = async (deviceID, newStatus) => {
      try {      
        if (deviceID !== undefined && newStatus === DEVICE_STATUS.ON) {
          await dispatch(updateComponentInDB({aid: props.activityID, cid: componentID, status: newStatus, did: deviceID}));
          console.log("handleAction Device Database updated successfully");
        }
      } catch (error) {
        console.error("Error updating database:", error);
      }
    }

    const handleUpdateArgument = async (newArgument) => {
      try {      
        if (newArgument !== undefined) {
          console.log(JSON.stringify(newArgument)); //{"ecMax":"10","ecMin":"2", "phMin": "6", "phMax": "9"}
          await dispatch(updateComponentInDB({aid: props.activityID, cid: componentID, argument: newArgument}));
          console.log("handleAction Device Database updated successfully");
        }
      } catch (error) {
        console.error("Error updating database:", error);
      }
    }

    return (
        <div className="dash-zone-item-card-control dash-zone-item-card-control-ph-ec-pump">          
            {isComponentReady() ?
            <div className="form-check form-switch automatic-switch light-control-container-automatic">
            <input className="form-check-input" type="checkbox" role="switch" id="automaticSwitchCheckDefault" checked={isAutomatic} onChange={handleToggleAutomatic}/>
            <label className="form-check-label" htmlFor="automaticSwitchCheckDefault">{t('Common.Automatic')}</label>
            </div>:null}
            {isComponentReady()?
            <Fragment>
            <PhPlusControl deviceID={phPlusPumpDeviceID} handleAction={handleAction} isAutomatic={isAutomatic}/>
            <PhMinusControl deviceID={phMinusPumpDeviceID} handleAction={handleAction} isAutomatic={isAutomatic}/>
            <EcMinusControl deviceAID={ecAPumpDeviceID} deviceBID={ecBPumpDeviceID} handleAction={handleAction} isAutomatic={isAutomatic}/>
            {props.isWithPlusWL?<WaterLevelPlusControl deviceID={waterPlusPumpDeviceID} handleAction={handleAction} isAutomatic={isAutomatic}/>: null}
            </Fragment>
            :null}    
            <SliderComponent className="slider" title={t('Common.SET_EC_RANGE')} deviceID={ecID} selectedLow={argument.ecMin} selectedHigh={argument.ecMax} setSelected={(valueLow, valueHigh)=>{console.log("calleed setSelected"); handleUpdateArgument({"ecMax": valueHigh, "ecMin": valueLow})}}/>
            <SliderComponent className="slider" title={t('Common.SET_PH_RANGE')} deviceID={phID} selectedLow={argument.phMin} selectedHigh={argument.phMax} setSelected={(valueLow, valueHigh)=>{handleUpdateArgument({"phMax": valueHigh, "phMin": valueLow})}}/>
      </div>
    );
}