import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_STATUS, CONTROL_SETTING_NAME } from "../../../common/constant";

import { useTranslation } from "react-i18next";
import { updateComponentInDB } from "../../../slices/data/thunk";

export function LightControl(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const componentID = props.componentID;
    const id = props.deviceID;
    
    const isAutomatic = useSelector(state => state.Data.component[componentID].isAutomatic);
    const argument = useSelector(state => state.Data.component[componentID].argument);
    const status = useSelector(state => state.Data.data[id]?.value);

    const handleToggleAutomatic = async () => {
        // Toggle the state
        const newAutomaticState = !isAutomatic;
    
        // Update the DB with the new state
        await updateAutomaticInDB(newAutomaticState);
      };
    
      const updateAutomaticInDB = async (newState) => {
        try {
          // Assuming updateComponentInDB is a function passed as a prop or defined elsewhere
          // This should be an API call to update the component in the database
          await dispatch(updateComponentInDB({aid: props.activityID, cid: componentID, isAutomatic: newState }));
          console.log("updateAutomaticInDB Database updated successfully");
        } catch (error) {
          console.error("Error updating database:", error);
        }
      };
    
      const updateTurnOnTime = async (e) => {
        try {
          const newTime = e.target.value; 
          await dispatch(updateComponentInDB({aid: props.activityID, cid: componentID, argument: {...argument, [CONTROL_SETTING_NAME.LUMINANCE_LIGHT.TURN_ON_TIME]: newTime}}));
          console.log("updateTurnOnTime Database updated successfully");
        } catch (error) {
          console.error("Error updating database:", error);
        }
      };
    
      const updateTurnOffTime = async (e) => {
        try {
          const newTime = e.target.value; 
          await dispatch(updateComponentInDB({aid: props.activityID, cid: componentID, argument: {...argument, [CONTROL_SETTING_NAME.LUMINANCE_LIGHT.TURN_OFF_TIME]: newTime}}));
          console.log("updateTurnOffTime Database updated successfully");
        } catch (error) {
          console.error("Error updating database:", error);
        }
      };
    
    
      const handleToggleSwitchDevice = async (event) => {
        try {      
          const isChecked = event.target.checked; // true if "on", false if "off"
          const newStatus = isChecked ? DEVICE_STATUS.ON : DEVICE_STATUS.OFF;
          await dispatch(updateComponentInDB({aid: props.activityID, cid: componentID, status: newStatus, did: id}));
          console.log("handleToggleSwitchDevice Database updated successfully");
        } catch (error) {
          console.error("Error updating database:", error);
        }
      }

    return (
        <div className="dash-zone-item-card-control">
          {status !== undefined && status !== DEVICE_STATUS.NO_STATUS?
          <div className="form-check form-switch automatic-switch light-control-container-automatic">
          <input className="form-check-input" type="checkbox" role="switch" id="automaticSwitchCheckDefault" checked={isAutomatic} onChange={handleToggleAutomatic}/>
          <label className="form-check-label" htmlFor="automaticSwitchCheckDefault">{t('Common.Automatic')}</label>
          </div>:null}
            <div className="light-control-container-title">
                <div className="title">{t('Common.DeviceType.luminance_light')}</div>
                {status === undefined || status === DEVICE_STATUS.NO_STATUS? <div>{t('Common.NO_STATUS')}</div>:
                <div className="form-check form-switch on-off-switch">
                <label className="form-check-label left-label" htmlFor="onOffSwitchCheckDefault">{t('Common.OFF')}</label>
                <input className="form-check-input" type="checkbox" role="switch" id="onOffSwitchCheckDefault" checked={status===DEVICE_STATUS.ON} onChange={handleToggleSwitchDevice} disabled={isAutomatic}/>
                <label className="form-check-label right-label" htmlFor="onOffSwitchCheckDefault">{t('Common.ON')}</label>
                </div>
            }
            </div>
            {isAutomatic && status !== undefined && status !== DEVICE_STATUS.NO_STATUS?
            <div className="light-control-container-control">
            <div className="label">{t('Common.TURN_ON')}</div>
            <input type="time" id="turnOnTimer" value={argument[CONTROL_SETTING_NAME.LUMINANCE_LIGHT.TURN_ON_TIME]} onChange={updateTurnOnTime} />
            <div className="label">{t('Common.TURN_OFF')}</div>
            <input type="time" id="turnOffTimer" value={argument[CONTROL_SETTING_NAME.LUMINANCE_LIGHT.TURN_OFF_TIME]} onChange={updateTurnOffTime} />
            </div>:null}       
      </div>
    );
}