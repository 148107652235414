import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEVICE_STATUS } from '../common/constant';
import { useTranslation } from 'react-i18next';
import { faArrowUpFromWaterPump } from '@fortawesome/free-solid-svg-icons';
import Spinner from './Spinner';
import { useSelector } from 'react-redux';
import UpArrowButton from './UpArrowButton';

const EcMinusControl = ({ deviceAID, deviceBID, handleAction, isAutomatic }) => {
    const { t } = useTranslation();    
    const statusA = useSelector(state => state.Data.data[deviceAID]?.value);
    const statusB = useSelector(state => state.Data.data[deviceBID]?.value);
    const [loading, setLoading] = useState(false);
    const prevStatusARef = useRef(statusA);
    const prevStatusBRef = useRef(statusB);

    useEffect(() => {
        if (prevStatusARef.current !== statusA) {
            console.log(`Status changed from ${prevStatusARef.current} to ${statusA}`);
            if (statusA === DEVICE_STATUS.ON && statusB === DEVICE_STATUS.ON) {
                setLoading(false);
            }
            prevStatusARef.current = statusA;
        }
    }, [statusA]); 

    useEffect(() => {
        if (prevStatusBRef.current !== statusB) {
            console.log(`Status changed from ${prevStatusBRef.current} to ${statusB}`);
            if (statusA === DEVICE_STATUS.ON && statusB === DEVICE_STATUS.ON) {
                setLoading(false);
            }
            prevStatusBRef.current = statusB;
        }
    }, [statusB]); 

    return (
        <div className="pump-control-container-title">
            <div className="title">{t('Common.DeviceType.ec_pump')}</div>
            {statusA === undefined || statusA === DEVICE_STATUS.NO_STATUS? <div>{t('Common.NO_STATUS')}</div>:
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center"}}>
            <FontAwesomeIcon size={"2x"} icon={faArrowUpFromWaterPump} style={{ color: statusA === DEVICE_STATUS.ON ? 'green' : 'gray' }}/>
            <FontAwesomeIcon size={"2x"} icon={faArrowUpFromWaterPump} style={{ color: statusB === DEVICE_STATUS.ON ? 'green' : 'gray' }}/>
            {isAutomatic?null:
            <UpArrowButton size={"4x"} enabled={statusA === DEVICE_STATUS.ON || statusB === DEVICE_STATUS.ON ? false: true} onClick={()=>{console.log("Click the up arrow"); 
            if (statusA === DEVICE_STATUS.OFF && statusB === DEVICE_STATUS.OFF) {
                setLoading(true); 
                handleAction(deviceAID, DEVICE_STATUS.ON); handleAction(deviceBID, DEVICE_STATUS.ON);}
            }} />}
            </div>}
            {loading&&!isAutomatic?<Spinner/>:null}
        </div>
    );
};

export default EcMinusControl;
