import React, { useEffect, useRef, useState } from 'react';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import { useSelector } from 'react-redux';

const SliderComponent = (props) => {
  const sliderRef = useRef(null); // Create a ref to attach the slider to
  const device = useSelector(state => state.Data.device[props.deviceID]);
  console.log("selectedLow: " + props.selectedLow);
  console.log("selectedHigh: " + props.selectedHigh);
  console.log("min: " + (device!==undefined?parseFloat(device.deviceTypeValueMin):0));
  console.log("max: " + (device!==undefined?parseFloat(device.deviceTypeValueMax):0));

  useEffect(() => {
    if (sliderRef.current) {
      // Initialize noUiSlider
      noUiSlider.create(sliderRef.current, {
        start: [props.selectedLow, props.selectedHigh],       // Initial slider range values
        connect: true,         // Show a filled area between handles
        range: {
          min: device!==undefined?parseFloat(device.deviceTypeValueMin):0,
          max: device!==undefined?parseFloat(device.deviceTypeValueMax):0
        }
      });

      // Optional: Handle updates on slider value change
      sliderRef.current.noUiSlider.on('change', (values) => {
        console.log('Slider values:', values);
        console.log(typeof props.selectedLow);
        console.log(typeof values[0]);
        if (parseFloat(values[0]) !== parseFloat(props.selectedLow) || parseFloat(values[1]) !== parseFloat(props.selectedHigh)) {
          props.setSelected(values[0], values[1]);
        }
      });
    }

    // Cleanup: Destroy the slider on component unmount
    return () => {
      if (sliderRef.current) {
        sliderRef.current.noUiSlider.destroy();
      }
    };
  }, []);

  return (
    <div className={`slider-component-container ${props.className}`}>
        <div className='slider-title'>{props.title} {props.selectedLow}, {props.selectedHigh}</div>
        <div style={{width: "100%", padding: 10}}>
          <div style={{width: "100%"}} ref={sliderRef}></div>
        </div>
    </div>
    ); // Attach slider to this div
};

export default SliderComponent;
