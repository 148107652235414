import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const WarningSign = ({ size = "3x", color = "#ffc107", style = {}, title = "Warning", ...props }) => {
  return (
    <FontAwesomeIcon 
      icon={faTriangleExclamation} 
      size={size} 
      style={{ color, marginLeft: 10, ...style }} 
      title={title} // Tooltip text
      {...props}
    />
  );
};

export default WarningSign;