import React, { Fragment, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DEVICE_TYPE, DEVICE_VALUE_TYPE } from "../../../common/constant";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";
import { getIcon, getUnit } from "../../../common/util";
import { EcPhPumpControl } from "../devices/EcPhPumpControl";
import WarningSign from "../../../components/WarningSign";

function EC_PH_MIX_PUMP(props) {
  const { t } = useTranslation();
  const id = props.componentId;
  const component = useSelector(state => state.Data.component[id]);
  const deviceIds = component.devices;
  const devicesState = useSelector(state => state.Data.data);
  const [phPlusPumpControlID, setPhPlusPumpControlID] = useState(null);  
  const [phMinusPumpControlID, setPhMinusPumpControlID] = useState(null);  
  const [ecAPumpControlID, setEcAPumpControlID] = useState(null);
  const [ecBPumpControlID, setEcBPumpControlID] = useState(null);
  const [ecID, setEcID] = useState(null);
  const [phID, setPhID] = useState(null);
  const [waterPlusPumpControlID, setWaterPlusPumpControlID] = useState(null);
  const [waterLevelID, setWaterLevelID] = useState(null);
  const devicesInfo = useSelector(state => state.Data.device);
  
  const data = useMemo(() => {
    return deviceIds.reduce((acc, id) => {
      // Fetching the device data from the Redux store
      acc[id] = devicesState?.[id];
      if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.EC_A_PUMP) {
        setEcAPumpControlID(id);
      }
      else if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.EC_B_PUMP) {
        setEcBPumpControlID(id);
      }
      else if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.PH_MINUS_PUMP) {
        setPhMinusPumpControlID(id);
      }
      else if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.PH_PLUS_PUMP) {
        setPhPlusPumpControlID(id);
      }
      else if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.EC) {
        setEcID(id);
      }
      else if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.PH) {
        setPhID(id);
      }
      else if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.WATER_LEVEL) {
        setWaterLevelID(id);
      }
      else if (devicesInfo?.[id].deviceTypeName === DEVICE_TYPE.WATER_PLUS_PUMP) {
        setWaterPlusPumpControlID(id);
      }
      return acc;
    }, {});
  }, [deviceIds, devicesState]); // Dependencies are deviceIds and devicesState

  // Memoize the data object with useMemo
  const devices = useMemo(() => {
    return deviceIds.reduce((acc, id) => {
      // Fetching the device data from the Redux store
      acc[id] = devicesInfo?.[id];
      return acc;
    }, {});
  }, [deviceIds, devicesInfo]); // Dependencies are deviceIds and devicesState

  const divName = "C_" + component.componentId;
  console.log(data);
  return (
    <div className="card dash-zone-item-card dash-zone-item-card-ec-ph-mix-pump">
      <div className="body">          
          {
            component.devices.map(deviceID => {
              let device = devices[deviceID];
              if (device.deviceValueType === DEVICE_VALUE_TYPE.MONITOR) {
              return (
                <div key={device.deviceId} className="row dash-zone-item">
                  <div className="col-12 dash-zone-item-header-container">
                    <div className="dash-zone-item-header">{t('Common.DeviceType.' + device.deviceTypeName)}</div>
                  </div>
                  <div className="col-12 dash-zone-item-container">
                    <div className="col-4 dash-zone-item-icon"><FontAwesomeIcon icon={getIcon(device.deviceTypeName)} /></div>
                    <div className="col-8 dash-zone-item-text-container">
                      {data[device.deviceId] === undefined || data[device.deviceId].value === undefined ? "-" :
                        <Fragment>
                          <div className="dash-zone-item-text">{data[device.deviceId].value}</div>
                          <div className="dash-zone-item-unit">{getUnit(device.deviceTypeValueUnit)}</div>
                          {(data[device.deviceId].alert === undefined || !data[device.deviceId].alert)?
                          <div id={divName} style={{ width: "100%", height: "40px", marginLeft: '2rem', maxWidth: "200px" }}></div>
                          :<WarningSign title={t("ALERT_MSG." + data[device.deviceId].alertMsg)}/>}                          
                        </Fragment>
                      }
                    </div>
                  </div>    
                </div>
              );
              }
            })
          }      
      </div>
      <EcPhPumpControl activityID={props.activityID} componentID={id} phPlusPumpDeviceID={phPlusPumpControlID} phMinusPumpDeviceID={phMinusPumpControlID} ecAPumpDeviceID={ecAPumpControlID} ecBPumpDeviceID={ecBPumpControlID} ecDeviceID={ecID} phDeviceID={phID} isWithPlusWL={props.isWithPlusWL} waterPlusPumpDeviceID={waterPlusPumpControlID} />
    </div>
  );
}

export default EC_PH_MIX_PUMP;